.ihMapListings>li a {
  cursor: pointer;
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .ihNotifications li p {
    margin: 0 auto;
    width: 90%;
    font-size: 12px;
  }
}

#banking-login {
  display: none !important;
}

.ihLogin {
  display: none;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .ihNoteClose {
    z-index: 9;
    width: 20%;
    height: 100%;
    text-align: left;
    top: 3px;
    left: auto;
    right: 5px;
  }
}